<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>
            {{
              `${subscription.organization.name} - ${subscription.package.name}`
            }}
            <span v-if="isSubscriptionExpired" class="text-danger">
              <i class="ni ni-fat-remove"></i>
            </span>
            <span v-if="!isSubscriptionExpired" class="text-success">
              <i class="ni ni-check-bold"></i>
            </span>
          </h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_SUBSCRIPTIONS)"
            @click="editProduct"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="subscription.organization" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PACKAGES)"
        >
          <dt>{{ $t("COMMON.PACKAGE") }}</dt>
          <dd>
            <router-link
              v-if="subscription.package"
              :to="$objectViewRoute(subscription.package)"
            >
              {{ `${subscription.package.name}` }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SUBSCRIPTIONS.START_TIME") }}</dt>
          <dd>
            {{ subscription.start_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SUBSCRIPTIONS.END_TIME") }}</dt>
          <dd>
            {{ subscription.end_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ subscription.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ subscription.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "subscription-view-global",

  components: {},

  props: ["subscription"],

  data() {
    return {};
  },

  computed: {
    isSubscriptionExpired() {
      return moment(this.subscription.end_time) < moment();
    },
  },

  created() {},

  methods: {},

  mounted() {},

  watch: {
    subscription(subscription) {},
  },
};
</script>
